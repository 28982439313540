<template>
    <div class="Index" :class="{m_index:$store.state.screenType}">
        <Navbar></Navbar>
        <SwiperCom :list="banners" v-if="banners.length"></SwiperCom>
        <!--公司动态-->
        <div class="main">
            <Dynamic :list="companyNewsList"></Dynamic>
        </div>
        <!-- 新闻模块 -->
        <NowModule :list="newsList"></NowModule>
        <!--赛事动态-->
        <div class="main-game">
            <DynamicGame :list="raceNewsList"></DynamicGame>
        </div>
        <!--公司简介-->
        <IntroduceModule :company="company"></IntroduceModule>
        <!--企业文化-->
        <div :style="{'background-color':$store.state.screenType?'#fff':'#F5F5F3'}">
            <CultureModule :list="hopeList" :banner="hopeBannerList"></CultureModule>
        </div>
        <!--公司业务-->
        <BusinessModule :list="structureList"></BusinessModule>
        
        <Footer></Footer>
        <top></top>
    </div>
</template>

<script>
//引入导航栏
import Navbar from '../../components/Nav/Navbar'
//引入轮播图
import SwiperCom from '../../components/SwiperCom'
//引入底部
import Footer from '../../components/Footer/Footers'
import Dynamic from './IndexModule/Dynamic'
import TitleCom from '../../components/Index/TitleCom'
import NowModule from './IndexModule/NowModule'
import IntroduceModule from './IndexModule/IntroduceModule'
import CultureModule from './IndexModule/CultureModule'
import BusinessModule from './IndexModule/BusinessModule'
import DynamicGame from './IndexModule/DynamicGame'

export default {
    name: "Index",
    components: {
        Navbar,
        SwiperCom,
        Dynamic,
        TitleCom,
        Footer,
        NowModule,
        IntroduceModule,
        CultureModule,
        BusinessModule,
        DynamicGame
    },
    data () {
        return {
            typeColor: 0,
            smallImgList: [
                require('../../assets/index_banner_01.png')
            ],
            culImgList: [
                require('../../assets/index_banner_02.png')
            ],

            meta: {},//head头部内容
            banners: [], //轮播图
            company: '',//公司简介
            hopeList: [],//企业文化
            hopeBannerList: [],//企业文化轮播图
            structureList: [],//企业业务
            companyNewsList: [],//公司动态
            newsList: [],//新闻,
            raceNewsList: []//赛事动态

        }
    },
    methods: {
        //请求head数据
        getList () {
            this.$http.getPage(this.$store.state.languageIndex, 1).then(res => {
                //console.log(res)
                this.meta = res.data.data
                this.banners = res.data.data.banners
                //console.log(this.meta)
                this.$getMedia()
            })
        },
        //请求页面的所有数据
        getAllList () {
            this.$http.getHome(this.$store.state.languageIndex).then(res => {
                //console.log(res.data.data)
                this.company = res.data.data.companyIntroduce
                this.hopeList = res.data.data.hopeList
                this.structureList = res.data.data.structureList
                this.companyNewsList = res.data.data.companyNewsList.splice(0, 2)
                this.newsList = res.data.data.companyNewsList.splice(0, 3)
                this.raceNewsList = res.data.data.raceNewsList
                this.hopeBannerList = res.data.data.hopeBannerList
            })
        }
    },
    created () {
        this.getList()
        this.getAllList()
    },
    watch: {
        //监听vuex中的语言状态，当发生改变的时候，更新页面中的数据
        '$store.state.languageIndex' () {
            this.getList()
            this.getAllList()
        }
    }
}
</script>


<style scoped lang="scss">
$width: 104.875rem;
/*-- -------Index---------- --*/
.Index {

    .main {
        margin-top: 10.3125rem;
    }

    .main-game {
        background-color: #000;
        padding-top: 4.125rem;
        padding-bottom: 1.25rem;

    }

    .title-small {
        width: 100%;
        height: 3.5625rem;
        background: transparent url("../../assets/index/head_02.png") no-repeat left bottom;
        background-size: 15.5rem auto;
        font-size: 2.45rem;
        font-weight: 800;
    }
}

@import "m-index";
</style>
