<template>
    <div class="introduce" :class="{m_introduce:$store.state.screenType}">
        <!---->
        <div class="title-small" v-if="!$store.state.screenType">
            <span>{{ $t('Index.about').toUpperCase() }}</span>
        </div>
        <!---->
        <div class="dynamic-big-head" v-if="$store.state.screenType">
            <span>{{ $t('Index.about').toUpperCase() }}</span>
        </div>
        <div class="introduce_content">
            <!---->
            <div class="introduce_content_right"
                 v-if="$store.state.screenType">
                <SwiperCom :list="company.imgList" class="small-swiper" v-if="company.imgList"></SwiperCom>
            </div>
            <!---->
            <div class="introduce_content_left">
                <div class="simple ql-editor" v-html="company.text">
                </div>

                <!-- <div class="simple ql-editor">
                    <span class="text" style="margin-left:20px;" v-show="this.$store.state.languageIndex==1">
                        英雄体育VSPN成立于2016年，以电竞赛事和泛娱乐内容制作为核心，提供赛事组织及内容制作、电竞商业化、电竞社群运营、电竞综合体运营等综合服务，致力于创造全球电竞爱好者喜欢的内容和产品，推动电竞成为更具影响力的新一代运动及娱乐。
                    </span>

                    <span class="text" style="margin-left:20px;" v-show="this.$store.state.languageIndex==2">
                        Founded in 2016, VSPO is the largest esports event operator in Asia. We focus our business on tournament operations, commercialization, content matrix & integrated marketing, talent incubation and management, esports venue development, etc. We are dedicated to creating inspiring content and products for global esports enthusiasts, promoting esports as the sport and entertainment of the next generation.
                    </span>
                    
                </div> -->

                <div class="simple-to" @click="jumpPage(0)">
                    <div class="to-icon">
                        <span>{{ $t('Index.more').toUpperCase() }}</span>
                    </div>
                    <div class="next"></div>
                </div>
            </div>
            <!---->
            <div class="introduce_content_right" v-if="!$store.state.screenType">
                <SwiperCom :list="company.imgList" class="small-swiper" v-if="company.imgList"></SwiperCom>
            </div>
        </div>
        <div class="dynamic_title" @click="jumpPage(0)">
            <span>{{ $t('Index.more').toUpperCase() }}</span>
            <div class="next"></div>
        </div>
    </div>
</template>

<script>
import SwiperCom from '../../../components/SwiperCom'
import TitleCom from '../../../components/Index/TitleCom'

export default {
    name: "IntroduceModule",
    components: {
        TitleCom,
        SwiperCom
    },
    props: ['company'],
    data () {
        return {
            smallImgList: [
                require('../../../assets/index_banner_01.png')
            ]
        }
    },
    methods: {
        // 点击跳转链接
        jumpPage (index) {
            this.$router.push({
                path: '/introduce'
            })

            this.$store.commit('changeIndex', index)
        }
    }

}
</script>


<style scoped lang="scss">

/*-- -------IntroduceModule---------- --*/
.introduce {
    width: 100%;
    margin: 0 auto;
    padding: 12.5rem 7.5rem 7.75rem 7.5rem;
    position: relative;
    box-sizing: border-box;
    height: auto;
    max-width: 1920px;

    &_content {
        //height: 29.375rem;
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        height: 29.625rem;

        &_left {
            width: 50%;
            font-family: 'AlibabaPuHuiTiL';
            position: relative;
            height: 100%;

            .simple {
                padding: 5.5rem 5rem 7.5rem 0;
                font-size: 1.5rem;
                line-height: 3rem;
                box-sizing: border-box;
                .text{
                    font-size: 1.5rem;
                    line-height: 2.3rem;
                    font-family:'AlibabaPuHuiTiL';
                    text-indent:2rem; 
                }
            }

            .simple-to {
                display: flex;
                justify-content: left;
                align-items: center;
                cursor: pointer;
                position: absolute;
                bottom: 0;

                .to-icon {
                    width: auto;
                    height: 1.825rem;
                    margin-right: 0.75rem;
                    text-align: center;
                    border-radius: 25px;
                    line-height: 1.825rem;
                    background-color: #ED121B;
                    font-size: 0.875rem;
                    padding: 0 1.5rem;
                }

                .next {
                    width: 1.8125rem;
                    height: 1.25rem;
                    background-image: url('../../../assets/next_02.png');
                    background-size: 100% auto;
                }
            }
        }

        &_right {
            width: 50%;
            height: 100%;

            .small-swiper {
                width: 100%;
                height: 100%;
                //height: 29.375rem;
                overflow: hidden;
                //height: auto;
                ::v-deep .swiper-container {
                    width: 100%;
                    height: 100%;
                }

                ::v-deep .swiper-pagination {
                    bottom: 1rem;
                }

                ::v-deep [class^='swiper-button'] {
                    display: none !important;
                }
            }
        }
    }

    .dynamic_title {
        width: 9.375rem;
        line-height: 2.125rem;
        position: absolute;
        top: 0.875rem;
        right: 3.125rem;
        color: #9FA0A0;
        display: none;
        font-size: 1rem;

        .next {
            width: 1.4375rem;
            height: 1.0625rem;
            background-image: url('../../../assets/next_l_02.png');
            background-size: 100% auto;
            position: absolute;
            right: 0;
            top: calc(50% - 0.5rem);
        }
    }
}

::v-deep.TitleCom {
    font-size: 3rem;
}

.title-small {
    width: 100%;
    height: 3.5625rem;
    background: transparent url("../../../assets/index/head_02.png") no-repeat left bottom;
    background-size: 15.5rem auto;
    font-size: 3rem;
    font-family: 'PangMenZhengDao'
}

.dynamic-big-head {
    width: 25rem;
    height: 3.75rem;
    background: transparent url("../../../assets/index/head_01.png") no-repeat left bottom;
    background-size: 100% auto;
    font-size: 3rem;
    font-family: 'PangMenZhengDao';

    span {
        padding: 0;
    }
}

@import './css/introduce';
</style>
