<template>
    <div class="now" v-if="!$store.state.screenType">
        <div class="now_items" v-for="(value,index) in list" v-if="list.length" @click="jumpContent(value.newsId)">
            <div class="now_items_img">
                <img :src="imgUrl(baseUrl,value.coverUrl,'@t:320x320>')" alt="">
            </div>
            <div class="now_items_time">
                <div>
                    <span>{{ $getTime(value.createTime).day}}</span>
                    <span> {{ $getTime(value.createTime).year }}/{{ $getTime(value.createTime).month }}</span>
                </div>
            </div>
            <div class="now_items_text">
                <p class="title">{{ value.newsTitle }}</p>
                <p class="content">{{ value.newsDes }}</p>
                <div class="next"></div>
            </div>
        </div>

    </div>
</template>

<script>
import { imgUrl } from "@/utils/urlutils"
import { mapState } from 'vuex'

export default {
    name: "NowModule",
    components: {},
    props: ['list'],
    data () {
        return {

        }
    },
    methods: {
        imgUrl,
        jumpContent (id) {
            this.$router.push({
                path: '/content',
                query: {
                    id: id
                }
            })
        }
    },
    computed: {
        ...mapState(['baseUrl'])
    }

}
</script>


<style scoped lang="scss">

/*-- -------NowModule---------- --*/
.now {
    width: 100%;
    margin: 0 auto;
    padding: 6.875rem 7.5rem;
    box-sizing: border-box;
    max-width: 1920px;

    &_items {
        height: 17.9375rem;
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        border-bottom: 0.125rem solid #808080;
        cursor: pointer;
        &_img {
            width: 17%;
            height: 13.4375rem;
            margin: 2.6875rem 0;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &_time {
            width: 17%;
            height: 17.9375rem;
            position: relative;

            div {
                width: 4.6875rem;
                height: 4.6875rem;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                text-align: center;

                span {
                    display: block;
                }

                span:first-child {
                    font-size: 3.375rem;
                    line-height: 3.25rem;
                    font-family: 'AlibabaPuHuiTiM';
                }

                span:last-child {

                }
            }
        }

        &_text {
            width: 66%;
            height: 100%;
            padding: 5.0625rem 8.125rem 3.75rem 3.125rem;
            box-sizing: border-box;
            position: relative;

            .title {
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 2rem;
            }

            .content {
                width: 100%;
                font-size: 1.25rem;
                font-family: 'AlibabaPuHuiTiL';
            }

            .next {
                width: 1.75rem;
                height: 1.25rem;
                background-image: url('../../../assets/next_l_02.png');
                background-size: 100% auto;
                position: absolute;
                bottom: 1.5rem;
                right: 8.125rem;
            }
        }

        &:last-child {
            border: none;
        }
    }


}
</style>
