<template>
    <div class="culture" :class="{m_culture:$store.state.screenType}">
        <div class="dynamic-big-head" v-if="$store.state.screenType">
            <span>{{ $t('Index.culture').toUpperCase() }}</span>
        </div>
        <div class="culture_swiper">
            <SwiperCom :list="banner" class="swiper" v-if="banner.length"></SwiperCom>
        </div>
        <div class="culture_content">
            <div class="title-small" v-if="!$store.state.screenType">
                <span>{{ $t('Index.culture').toUpperCase() }}</span>
            </div>
            <div class="culture_content_text">
                <div class="item-text" v-for="(value,index) in list">
                    <h2>{{ value.title }}</h2>
                    <div class="items">
                        <div class="item " v-html="value.text"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleCom from '../../../components/Index/TitleCom'
import SwiperCom from '../../../components/SwiperCom'

export default {
    name: "CultureModule",
    components: {
        TitleCom,
        SwiperCom

    },
    props: ['list','banner'],
    data () {
        return {
            culImgList: [
                require('../../../assets/index_banner_02.png')
            ],

            cultureList: [
                {
                    name: '使命',
                    list: [
                        {
                            zh: '让全世界感受电竞的欢乐和力量',
                            en: 'Driving esports and empower the world'
                        }
                    ]
                },
                {
                    name: '愿景',
                    list: [
                        {
                            zh: '创造全球电竞爱好者喜欢的内容和产品，推动电竞成为更具影响力的新一代运动及娱乐',
                            en: 'Creating inspiring content and products for global esports enthusiasts, promoting esports as the sport and entertainment of the next-generation.'
                        }
                    ]
                },
                {
                    name: '价值观',
                    list: [
                        {
                            zh: '专业',
                            en: 'Expertise first'
                        },
                        {
                            zh: '成长',
                            en: 'Growth mindset'
                        }
                    ]
                }
            ]
        }
    }

}
</script>


<style scoped lang="scss">

/*-- -------CultureModule---------- --*/
.culture {
    width: 100%;
    //height: 52.5rem;
    margin: 0 auto;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    padding: 0 7.5rem;
    box-sizing: border-box;
    max-width: 1920px;

    &_swiper {
        width: 61%;
        //height: 100%;

        .swiper {
            height: 100%;
        }

        ::v-deep [class^='swiper-pagination'] {
            display: none;
        }

        ::v-deep [class^='swiper-container'] {
            height: 100%;

            img {
                width: 100%;
                height: 100%;
            }

        }
    }

    &_content {
        width: 39%;
        padding: 3.75rem 2.25rem 3.75rem 2.875rem;
        box-sizing: border-box;

        &_text {
            .item-text {
                margin-top: 2.625rem;

                h2 {
                    font-size: 2rem;
                }

                .items {
                    margin-top: 1.125rem;
                    width: 100%;
                    font-family: 'AlibabaPuHuiTiL';
                    font-size: 1.5rem;
                    line-height: 1.875rem;
                    padding-bottom: 1rem;

                }

                ::v-deep &:last-child {
                    .items {
                        .item {
                            p {
                                margin-bottom: 1rem;
                            }
                        }
                    }
                }

                ::v-deep &:last-child {
                    .items {
                        .item {
                            display: flex;
                            justify-content: left;
                            align-items: center;
                            flex-wrap: wrap;
                            margin-right: 3.125rem;
                            width: 100%;

                            p {
                                width: 50%;
                            }
                        }
                    }
                }
            }

        }

        @media screen and (max-width: 1300px) {
            .item-text {
                font-size: 0.75rem !important;
                margin-top: 1rem;

            }
        }

    }

    @media screen and (max-width: 1300px) {
        &_content {
            padding: 1.75rem 6.25rem 3.75rem 5.875rem;
        }
    }

}

.title-small {
    width: 100%;
    height: 3.5625rem;
    background: transparent url("../../../assets/index/head_02.png") no-repeat left bottom;
    background-size: 15.5rem auto;
    font-size: 3rem;
    font-family: 'PangMenZhengDao'
}

.dynamic-big-head {
    width: 25rem;
    height: 3.75rem;
    background: transparent url("../../../assets/index/head_01.png") no-repeat left bottom;
    background-size: 100% auto;
    font-size: 3rem;
    font-family: 'PangMenZhengDao';

    span {
        padding: 0;
    }
}

@import './css/culture';
</style>
