<template>
    <div class="TitleCom"
         :class="{m_title:$store.state.screenType,en_title:$store.state.language}">
        <span>{{ name }}</span>
    </div>
</template>

<script>
export default {
    name: "TitleCom",
    props: ['name']

}
</script>


<style scoped lang="scss">

/*-- -------TitleCom---------- --*/
.TitleCom {
    width: 29.0625rem;
    height: 3.5125rem;
    background: transparent url("../../assets/index/head_01.png") no-repeat left bottom;
    background-size: 20.0625rem auto;
    font-size: 2.25rem;
    //font-weight: 800;
    font-family: 'AlibabaPuHuiTiH';
}

.m_title {
    background-size: 16.0625rem auto;
    font-size: 2rem;
}

.en_title {
    font-size: 1.8rem;
    line-height: 2.125rem;
    height: 2.8125rem;
}
</style>
