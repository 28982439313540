<template>
    <div class="dynamic" :class="{m_dynamic:$store.state.screenType}">
        <div class="dynamic-big-head">
            <span>{{ $t('Index.event').toUpperCase() }}</span>
        </div>
        <div class="dynamic_main" v-if="list.length">
            <!---->
            <div class="dynamic_main_public" @click="jumpContent(list[0].newsId)">
                <img :src="imgUrl(baseUrl,list[0].coverUrl,'@t:1000x1000>')" alt="">
                <div class="public">
                    <h1>{{ list[0].newsTitle }}</h1>
                    <p>{{ list[0].newsDes }}</p>
                    <div class="next">
                        <span></span>
                    </div>
                    <div class="logo">
                        <div class="big">{{ $getTime(list[0].createTime).day }}</div>
                        <div class="small"> {{ $getTime(list[0].createTime).year }}/{{ $getTime(list[0].createTime).month }}</div>
                    </div>
                </div>
            </div>
            <!---->
            <div class="dynamic_main_slot" v-if="!$store.state.screenType">
                <div class="main_slot" @click="jumpContent(list[1].newsId)">
                    <div class="content">
                        <img :src="imgUrl(baseUrl,list[1].coverUrl,'@t:800x800>')" alt="">
                        <div class="content-text">
                            <p class="now-time">
                                <span>{{ $getTime(list[1].createTime).month }}/{{ $getTime(list[1].createTime).day }}</span>{{ $getTime(list[0].createTime).year }}
                            </p>
                            <div class="now-content">
                                <div class="content-not">
                                    {{ list[1].newsTitle }}
                                </div>
                                <div class="next"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main_all" @click="jumpPage">
                    <div class="href_all">
                        <span>{{ $t('Media.all').toUpperCase() }}</span>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="dynamic_title" @click="jumpPage">
            <span>{{ $t('Media.all').toUpperCase() }}</span>
            <div class="next"></div>
        </div>
    </div>
</template>

<script>
import { imgUrl } from "@/utils/urlutils"
import { mapState } from 'vuex'

export default {
    name: "Dynamic",
    props: ['list'],
    data () {
        return {}
    },
    methods: {
        imgUrl,
        jumpPage () {
                this.$router.push({
                    path: '/movement',
                    query: {
                        type: '赛事动态'
                    }
                })

        },
        jumpContent(id){
            this.$router.push({
                path: '/content',
                query:{
                    id:id
                }
            })
        }

    },
    computed: {
        ...mapState(['baseUrl'])
    }

}
</script>


<style scoped lang="scss">

/*-- -------Dynamic---------- --*/
.dynamic {
    width: 100%;
    margin: 0 auto;
    padding: 0 7.5rem;
    box-sizing: border-box;
    max-width: 1920px;

    .dynamic-big-head {
        width: 25rem;
        height: 3.75rem;
        background: transparent url("../../../assets/index/head_01.png") no-repeat left bottom;
        background-size: 100% auto;
        font-size: 3rem;
        font-family: 'PangMenZhengDao';
        color: #fff;
        span {
            padding: 0;
        }
    }

    &_main {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 5.25rem;

        &_public {
            width: 55%;
            //height: 53.4375rem;
            background-color: #ffff;
            cursor: pointer;
            img {
                width: 100%;
            }

            .public {
                padding: 7.5rem 2.75rem 1.5rem 2.75rem;
                box-sizing: border-box;

                position: relative;

                p {
                    margin-top: 1.5rem;
                    font-size: 1.5rem;
                    line-height: 2.25rem;
                    font-family: 'AlibabaPuHuiTiL';
                }

                .next {
                    position: relative;
                    height: 1.25rem;

                    span {
                        width: 1.75rem;
                        height: 1.25rem;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        background-image: url('../../../assets/next_l_02.png');
                        background-size: 100% auto;
                    }
                }


                .logo {
                    width: 10.1875rem;
                    height: 8.625rem;
                    position: absolute;
                    left: 2.75rem;
                    top: - 4.3125rem;
                    background-image: url(../../../assets/矢量智能对象.png);
                    background-size: 100% auto;

                    .big {

                        height: 4.0625rem;
                        padding: 1.625rem 2.0625rem 0 2.0625rem;
                        font-size: 5rem;
                        font-weight: 800;
                        line-height: 4.0625rem;
                    }

                    .small {
                        padding: 0 2.1875rem;
                        font-weight: 800;
                        font-size: 1.5rem;
                        //line-height: 2.25rem;
                    }
                }
            }
        }

        &_slot {
            width: 40%;
            //height: 53.4375rem;
            .content {
                width: 100%;
                height: 100%;
                font-size: 0;
                position: relative;

                img {
                    width: 100%;
                }

                .content-text {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 11.5rem;
                    padding: 2rem;
                    box-sizing: border-box;
                    background-color: #fff;

                    .now-time {
                        font-size: 1.1875rem;

                        span {
                            font-size: 2.6875rem;
                        }
                    }

                    .now-content {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-right: 0.9375rem;

                        .content-not {
                            width: 80%;
                            font-size: 1.375rem;
                            line-height: 1.875rem;
                        }

                        .next {
                            width: 1.75rem;
                            height: 1.1875rem;
                            background-image: url('../../../assets/next_l_01.png');
                            background-size: 100% auto;
                        }
                    }
                }
            }

            .main_slot {
                width: 100%;
                height: 39.75rem;
                margin-bottom: 1.5625rem;
                cursor: pointer;
            }

            .main_all {
                width: 100%;
                height: 12.75rem;
                background: #fff url("../../../assets/index/index_03.png") no-repeat center center;
                position: relative;
                cursor: pointer;
                .href_all {
                    width: 11.375rem;
                    height: 2.875rem;
                    background: #ED111C;
                    border-radius: 0.25rem;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span:first-child {
                        font-size: 1.5rem;
                        display: inline-block;
                    }

                    span:last-child {
                        width: 1.5625rem;
                        height: 1.125rem;
                        margin-left: 0.75rem;
                        background: transparent url("../../../assets/next_01.png");
                        background-size: 100% auto;
                    }
                }
            }

        }
    }

    &_title {
        display: none;
        font-family: 'SourceHanSansCN';
    }
}

.m_dynamic {
    width: 100%;
    position: relative;
    padding: 0;

    .dynamic-big-head {
        background-size: 16.0625rem auto;
        font-size: 2.5rem;
        color: #000 !important;
    }

    .dynamic_main {
        padding: 5rem 0;

        &_public {
            height: auto;
            width: 100%;

            .public {
                background-color: #F5F5F3 !important;

                h1 {
                    font-size: 1.875rem;
                    font-weight: 400;
                }

                p {

                }

                .next {
                    display: none;
                }

                .logo {
                    width: 8.1875rem;
                    height: 6.9375rem;

                    .big {
                        font-size: 4.5625rem;
                        padding: 0 1.625rem;
                        margin-top: 1.25rem;
                    }

                    .small {
                        font-size: 1.25rem;
                        text-align: center;
                        width: 100%;
                        padding: 0;
                    }
                }
            }
        }
    }

    .dynamic_title {
        width: 9.375rem;
        line-height: 2.125rem;
        position: absolute;
        top: 0.875rem;
        right: 0;
        color: #9FA0A0;
        display: block;
        font-size: 1rem;

        .next {
            width: 1.4375rem;
            height: 1.0625rem;
            background-image: url('../../../assets/next_l_02.png');
            background-repeat: no-repeat;
            background-size: 100% auto;
            position: absolute;
            right: 0;
            top: calc(50% - 0.53rem);
        }
    }
}
</style>
