<template>
    <div class="business" :class="{m_business:$store.state.screenType}">
        <div class="dynamic-big-head" v-if="$store.state.screenType">
            <span>{{ $t('Index.business').toUpperCase() }}</span>
        </div>
        <div class="title-small" v-if="!$store.state.screenType">
            <span>{{ $t('Index.business').toUpperCase() }}</span>
        </div>
        <div class="business_item">
            <div class="business_item_child" v-for="(value,index) in list" @click="jumpPage(index)">
                <div class="bitem">
                    <div class="img_box" v-show="true">
                        <img :src="imgUrl(baseUrl,value.ImageUrl,'@t:800x800>')" alt="">
                    </div>

                    <div class="footer-go">
                        <span>{{ value.Text }}</span>
                        <div class="next"></div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleCom from '../../../components/Index/TitleCom'
import { imgUrl } from "@/utils/urlutils"
import { mapState } from 'vuex'

export default {
    name: "BusinessModule",
    components: {
        TitleCom
    },
    props: ['list'],
    data () {
        return {}
    },
    methods: {
        imgUrl,
        // 点击跳转链接
        jumpPage (index) {
            this.$router.push({
                path: '/business'
            })
            this.$store.commit('changeIndex', index)
        }
    },
    computed: {
        ...mapState(['baseUrl'])
    }

}
</script>


<style scoped lang="scss">

/*-- -------BusinessModule---------- --*/
.business {
    //width: $width;
    width: 100%;
    margin: 0 auto;
    padding: 7.5rem 7.5rem 21.1875rem 7.5rem;
    box-sizing: border-box;
    max-width: 1920px;

    &_item {
        width: 100%;
        // height: 43.125rem;
        // height: auto;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        // background-color: #d8112b;

        &_child {
            width: 24%;
            //width: 25rem;
            height: 43.125rem;
            position: relative;
            margin-top: 3.75rem;
            cursor: pointer;

            .bitem{
                width: 100%;
                //height: 43.125rem;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                flex-direction: column;

                .img_box {

                    width: 100%;
                    height: 100%;
                    align-self: center;

                    img {
                        width: 100%;
                        //height: 100%;
                    }

                }
                .footer-go {
                    margin-top: 0.75rem;
                    // position: absolute;
                    background-color: #F5F5F3;
                    bottom: 0;
                    left: 0;
                    padding: 2.625rem 2.3125rem;
                    font-size: 2.0125rem;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    box-sizing: border-box;
                    align-items: center;
                    height: 1.825rem;

                    span {
                        width: 80%;
                    }

                    .next {
                        width: 1.8125rem;
                        height: 1.05rem;
                        background-image: url('../../../assets/next_02.png');
                        background-size: 100% auto;
                    }
                }

            
            }
            
        }
    }
}

.title-small {
    width: 100%;
    height: 3.5625rem;
    background: transparent url("../../../assets/index/head_02.png") no-repeat left bottom;
    background-size: 15.5rem auto;
    font-size: 3rem;
    font-family: 'PangMenZhengDao'
}

.dynamic-big-head {
    width: 25rem;
    height: 3.75rem;
    background: transparent url("../../../assets/index/head_01.png") no-repeat left bottom;
    background-size: 100% auto;
    font-size: 3rem;
    font-family: 'PangMenZhengDao';

    span {
        padding: 0;
    }
}

@import "css/business";
</style>
